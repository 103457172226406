import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { asrTypes } from 'config/config';
import { getIdFromUrl } from 'helpers/formatHelpers';
import { userAware } from 'components/UserProvider';

import theme from './CoachSelectAsr.scss';
import axios from 'config/axios';

class CoachSelectAsr extends React.Component {
  state = {
    modalVisible: false,
    asr: '',
    asrSelected: false,
  };

  getStagesByUser = async (clientId) => {
    return axios
      .get(`programs/acne/stages/?acne_program__user=${clientId}`, {
        params: {
          limit: 50, // FIXME: Ideally, we need to call programs/acne/stages/?acne_program__user=${clientId}&check_in=${responseId}
        },
      })
      .then((response) => response.data);
  };

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  handleChange = (e) => {
    this.setState({
      asr: e.target.value,
    });
  };

  confirmAsrSelection = async () => {
    const { asr } = this.state;

    if (asr && asr !== '') {
      const updatedStage = await Promise.resolve(this.updateProgramStage(asr));

      if (
        updatedStage &&
        'severity_rating' in updatedStage &&
        updatedStage.severity_rating === asr
      ) {
        this.setState({
          modalVisible: false,
          asrSelected: true,
        });
      }
    }
  };

  // eslint-disable-next-line consistent-return
  updateProgramStage = async (asr) => {
    const userStages = await Promise.resolve(
      this.getStagesByUser(this.props.clientId),
    );

    if (userStages && userStages.results && userStages.results.length) {
      for (let i = 0; i < userStages.results.length; i++) {
        const stage = userStages.results[i];
        if (stage.check_in) {
          const checkinId = getIdFromUrl(stage.check_in);

          // eslint-disable-next-line max-depth
          if (checkinId === parseInt(this.props.responseId, 10)) {
            const stageId = userStages.results[i].id;

            return this.patchProgramStage(stageId, asr);
          }
        }
      }
    }
  };

  patchProgramStage = async (stageId, asr) => {
    return axios
      .patch(
        `programs/acne/stages/${stageId}/`,
        JSON.stringify({ severity_rating: asr }),
      )
      .then((response) => response.data);
  };

  render() {
    const { asr, asrSelected, modalVisible } = this.state;

    return (
      <div>
        {!asrSelected && (
          <button
            className={theme.selectAsrBtn}
            onClick={() => this.toggleModal()}
          >
            Select ASR
          </button>
        )}
        {modalVisible && (
          <div className={theme.modal}>
            <div className={theme.modalContainer}>
              <header>
                <h2>Clear Skin Specialist - Select ASR</h2>
                <button className={theme.closeModal} onClick={this.toggleModal}>
                  <i className={cx('material-icons')}>close</i>
                </button>
              </header>
              <div>
                <select value={asr} onChange={this.handleChange}>
                  <option value="" disabled>
                    Select an ASR
                  </option>
                  {asrTypes.map((asrType, i) => {
                    const key = Object.keys(asrType)[0];
                    const value = asrType[key];
                    return (
                      <option key={i} value={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <footer>
                <button
                  disabled={!asr || asr === ''}
                  onClick={this.confirmAsrSelection}
                >
                  Confirm
                </button>
              </footer>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CoachSelectAsr.propTypes = {
  user: PropTypes.object.isRequired,
  clientId: PropTypes.string,
  responseId: PropTypes.string,
};

export default userAware(CoachSelectAsr);
