/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import axios from 'config/axios';
import emptyMessage from 'assets/images/emptyMessage.png';
import { useLoggedInUser, useInterval, usePaginator } from 'hooks';
import { isClient } from 'helpers/user';
import {
  hasParticipant,
  getClientInThread,
  getClientRecipientForCoach,
  getCoachRecipientForClient,
} from 'helpers/thread';
import { isShipping } from 'helpers/message';
import ThreadService from 'services/thread.service';
import Loader from 'components/Loader';
import MessageList from 'components/MessageList';
import { useBranch } from 'components/BranchProvider';

import { Compose, Details, ChatRedirect } from './components';
import * as actions from './Conversation.ducks';
import theme from './Conversation.sss';

function ConversationPage({ loading, thread, initialize, sendMessage }) {
  const { threadId } = useParams();
  const history = useHistory();
  const user = useLoggedInUser();
  const [branch] = useBranch();

  const [scrollRef, loaderRef, paginatorState, { setData }] = usePaginator({
    debugId: `Thread-${threadId}`,
    endpoint: 'messages/',
    params: { thread: threadId },
    direction: 'top',
  });

  const delay = isClient(user) ? 10000 : null;
  useInterval(async () => {
    let response;
    try {
      response = await axios.get('messages/', {
        params: { thread: threadId },
      });
    } catch (error) {
      return;
    }

    const oldIds = paginatorState.data.map(({ id }) => id);
    const newEntries = response.data.results.filter(
      ({ id }) => !oldIds.includes(id),
    );
    if (newEntries.length) {
      setData([...newEntries, ...paginatorState.data]);
    }
  }, delay);

  useEffect(() => {
    if (!branch.messaging_enabled) {
      history.replace('/');
    }
  });

  useEffect(() => {
    initialize({ threadId, user }).then((success) => {
      if (!success) {
        history.replace('/');
      }
    });
  }, [threadId, user, history, initialize]);

  async function handleOpenThread() {
    const client = getClientInThread(thread);
    const clientThread = await ThreadService.create([client]);
    history.push(`/messages/${clientThread.id}`);
  }

  async function handleSubmitMessage({ message, messageType, attachment }) {
    const recipient = isClient(user)
      ? getCoachRecipientForClient(thread, user)
      : getClientRecipientForCoach(thread, user);

    if (!recipient) {
      throw new Error('Recipient not found');
    }

    const newMessage = await sendMessage({
      thread: thread.id,
      body: message,
      type: messageType,
      attachment: attachment.file,
      recipient: recipient.id,
    });
    if (newMessage && !isShipping(newMessage)) {
      setData([newMessage, ...paginatorState.data]);
    }
  }

  function indexMessagesByDay() {
    return uniqBy(paginatorState.data, 'id').reduce((acc, message) => {
      const day = moment(message.created).format('YYYY-MM-DD');
      return {
        ...acc,
        [day]: [...(acc[day] ?? []), message],
      };
    }, {});
  }

  function getEmptyMessage() {
    return <img src={emptyMessage} className={theme.EmptyMessageList}></img>;
  }

  if (
    loading ||
    isEmpty(thread) ||
    (paginatorState.isLoading && paginatorState.currentPage === 0)
  ) {
    return <Loader />;
  }

  const messages = indexMessagesByDay();

  return (
    <div className={theme.ConversationPage}>
      <Details thread={thread} />
      <MessageList
        messages={messages}
        emptyMessage={getEmptyMessage()}
        ref={scrollRef}
        loadingRef={loaderRef}
      />
      {hasParticipant(thread, user) ? (
        <Compose onSubmit={handleSubmitMessage} />
      ) : (
        <ChatRedirect thread={thread} openThread={handleOpenThread} />
      )}
    </div>
  );
}

ConversationPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  thread: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ conversation }) => ({
  ...conversation,
});

export default connect(mapStateToProps, actions)(ConversationPage);
